import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class DocumentsApi {
  getAdminDocuments = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.documentsURL}/documents`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getMyDocuments = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.documentsURL}/documents/mine`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  getDocument = async (msal, accounts, name) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios
      .get(`${env.urls.documentsURL}/documents/${name}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  deleteDocument = async (msal, accounts, doc) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios
      .delete(`${env.urls.documentsURL}/documents/${doc.id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  createInfo = async (msal, accounts, doc) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .post(`${env.urls.documentsURL}/documents/info`, doc, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  updateInfo = async (msal, accounts, doc) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .put(`${env.urls.documentsURL}/documents/info`, doc, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }

  uploadDocument = async (msal, accounts, contentType, data, docId) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios({
      url: `${env.urls.documentsURL}/documents/file/${docId}`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`
      }
    })
  }

  updateDocument = async (msal, accounts, contentType, data, docId) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios({
      url: `${env.urls.documentsURL}/documents/file/update/${docId}`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`
      }
    })
  }

  downloadDocument = async (msal, accounts, data) => {
    let token = await acquireAccessToken(msal, accounts, env)
    var form = new FormData()
    form.append('token', token)
    form.append('useMime', false)
    return await axios({
      url: `${env.urls.documentsURL}/documents/file/throughtoken/${data.id}`,
      method: 'POST',
      data: form,
      responseType: 'blob',
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      const href = URL.createObjectURL(response.data)

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', data.fileName) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  addUserOverride = async (msal, accounts, id, userId) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .post(
        `${env.urls.documentsURL}/documents/${id}/addoverride/${userId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 30000
        }
      )
      .then(result => result.data)
  }

  removeUserOverride = async (msal, accounts, id, userId) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .post(
        `${env.urls.documentsURL}/documents/${id}/removeoverride/${userId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 30000
        }
      )
      .then(result => result.data)
  }

  removeDocRefFromPackages = async (msal, accounts, id) => {
    let token = await acquireAccessToken(msal, accounts, env)

    return await axios
      .delete(`${env.urls.documentsURL}/packages/deletedoc/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }
}
const getInstance = () => {
  if (instance == null) {
    instance = new DocumentsApi()
  }
  return instance
}

export default getInstance()
