import * as React from 'react'
import { CircularProgress, DialogContent } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  CenteredDiv,
  CustomSelect,
  ErrorMessage,
  parseJwt,
  PrimaryButton,
  SnackbarVariants,
  useWtxLocalization,
  SlideUpDialog,
  WtxColors
} from '@wavetronix/common-components'
import DocumentTypeSelect from '../DocumentTypeSelect'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { acquireAccessToken } from '@wavetronix/common-components'
import { env } from '../../index.js'
import { TextField } from '@mui/material'
import DocumentsApi from '../../api/DocumentsApi'
import ProductTypeSelector from '../controls/ProductTypeSelector'
import CategoriesApi from '../../api/CategoriesApi'
import FileUploader from '../controls/FileUploader'
import CrmProductsApi from '../../api/CrmProductsApi'
import CrmProductTypeSelector from '../controls/CrmProductTypeSelector'

const classes = {
  paper: {
    position: 'absolute',
    width: '50%',
    height: '40%',
    backgroundColor: 'white',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '16px 32px 24px'
  },
  outside: {
    backgroundColor: 'white'
  },
  fileMetadata: {
    width: '80%',
    marginTop: '15px'
  }
}

export default function UploadDocumentModal(props) {
  const { instance, accounts } = useMsal()
  const [docAccessLevel, setDocAccessLevel] = useState('Intermediate')
  const [docType, setDocType] = useState(null)
  const [docProducts, setDocProducts] = useState([])
  const [docCRMProducts, setDocCRMProducts] = useState([])
  const [docLanguages, setDocLanguages] = useState([])
  const [docDescription, setDocDescription] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [uploadToken, setUploadToken] = useState('')
  const [file, setFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [documentTypes, setDocumentTypes] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [languageTypes, setLanguageTypes] = useState([])
  const [docOrder, setDocOrder] = useState(1)
  let localizedStrings = useWtxLocalization()

  const {
    data: categories,
    isLoading: catergoriesLoading,
    error: catergoriesError
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let categories = await CategoriesApi.getCategories(instance, accounts)
      return categories.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
  })

  const { data: crmProducts } = useQuery({
    queryKey: ['crmProducts'],
    queryFn: async () => {
      let categories = await CrmProductsApi.getCrmProducts(instance, accounts)
      return categories.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
  })

  useEffect(() => {
    const updateDocType = value => {
      setDocType(value)
    }

    if (categories) {
      let documentTypes = categories
        .filter(c => c.categoryType === 'Document Type')
        .sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
      let productTypes = categories.filter(c => c.categoryType === 'Product').sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
      let langTypes = categories.filter(c => c.categoryType === 'Language').sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))

      setDocumentTypes(documentTypes)
      setProductTypes(productTypes)
      setLanguageTypes(langTypes)
      updateDocType(documentTypes[0])
    }
  }, [categories])

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }
    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      resetUploadToken()
    }
  }, [instance, accounts, uploadToken])

  function createDocInfo() {
    let docInfo = {
      description: docDescription,
      accessLevel: docAccessLevel,
      documentType: docType.id,
      products: docProducts.map(prod => prod.id),
      crmProducts: docCRMProducts,
      language: docLanguages.map(lang => lang.id),
      fileName: file.name,
      order: docOrder
    }
    return docInfo
  }

  async function uploadWithFormData() {
    const formData = new FormData()
    formData.append('document', file)
    let infoKey = enqueueSnackbar(`Uploading file info...`, SnackbarVariants.LOADING)
    let docInfo = createDocInfo()
    await DocumentsApi.createInfo(instance, accounts, docInfo)
      .then(async response => {
        closeSnackbar(infoKey)
        enqueueSnackbar(localizedStrings.snackbar.infoUploaded, SnackbarVariants.SUCCESS)
        let fileKey = enqueueSnackbar(`Uploading file to blob storage...`, SnackbarVariants.LOADING)

        await DocumentsApi.uploadDocument(instance, accounts, 'multipart/form-data', formData, response)
          .then(response => {
            closeSnackbar(fileKey)
            enqueueSnackbar(localizedStrings.snackbar.docUploaded, SnackbarVariants.SUCCESS)
          })
          .catch(error => {
            closeSnackbar(fileKey)
            enqueueSnackbar(localizedStrings.snackbar.docUploadedFailed, SnackbarVariants.ERROR)
          })
      })
      .catch(error => {
        enqueueSnackbar(localizedStrings.snackbar.infoUploadedFailed, SnackbarVariants.ERROR)
      })
    closeSnackbar(infoKey)
  }

  function onClose() {
    props.refetch()
    props.onClose()
    setDocAccessLevel('Intermediate')
    setDocDescription('')
    setDocOrder(1)
    setFile(null)
    setDocType(documentTypes[0])
    setDocProducts([])
    setDocLanguages([])
    setDocCRMProducts([])
  }

  if (catergoriesLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  if (catergoriesError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={catergoriesError} />
      </CenteredDiv>
    )
  }

  return (
    <SlideUpDialog
      id='uploadDocumentModal'
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>Upload Document</h3>}
      actions={
        <PrimaryButton
          id='uploadDocumentButton'
          style={{ marginLeft: 5 }}
          disabled={
            !(
              file &&
              docDescription !== '' &&
              isUploading === false &&
              docProducts.length > 0 &&
              docLanguages.length > 0 &&
              file &&
              file.size < 1900000000
            )
          }
          onClick={async () => {
            setIsUploading(true)
            await uploadWithFormData()
            setIsUploading(false)
            onClose()
          }}
        >
          &nbsp;Upload
        </PrimaryButton>
      }
    >
      <DialogContent>
        <>
          <CenteredDiv>
            <CustomSelect
              id='documentAccessLevelSelect'
              style={{ width: '60%', marginTop: 30 }}
              label={localizedStrings.accessLevel}
              required={true}
              disabled={isUploading}
              value={docAccessLevel}
              onChange={e => setDocAccessLevel(e.target.value)}
              options={['Basic', 'Intermediate', 'Advanced']}
            />
            <TextField
              id='documentOrderTextField'
              inputProps={{ min: 1 }}
              type='number'
              disabled={isUploading}
              variant='outlined'
              style={{ marginTop: 30, marginLeft: 10, width: '18%' }}
              onChange={e => setDocOrder(e.target.value)}
              label={localizedStrings.order}
              required
              value={docOrder}
              size='small'
            />
          </CenteredDiv>
          <CenteredDiv>
            <ProductTypeSelector
              title={localizedStrings.products}
              selectorStyle={{ display: 'flex', width: '80%', marginTop: 15 }}
              documentProductTypes={docProducts}
              productTypes={productTypes}
              onChange={productTypes => setDocProducts(productTypes)}
            />
          </CenteredDiv>
          <CenteredDiv>
            <CrmProductTypeSelector
              title={'CRM Products'}
              selectorStyle={{ display: 'flex', width: '80%', marginTop: 15 }}
              documentProductTypes={docCRMProducts}
              productTypes={crmProducts}
              onChange={crmTypes => {
                return setDocCRMProducts([...crmTypes.map(t => t.id)])
              }}
            />
          </CenteredDiv>
          <CenteredDiv>
            <DocumentTypeSelect
              style={{ width: '80%', marginTop: 15 }}
              required
              disabled={isUploading}
              currentValue={docType}
              documentTypes={documentTypes}
              onChange={docType => setDocType(docType)}
            />
          </CenteredDiv>
          <CenteredDiv>
            <ProductTypeSelector
              title={localizedStrings.languages}
              selectorStyle={{ display: 'flex', width: '80%', marginTop: 15 }}
              documentProductTypes={docLanguages}
              productTypes={languageTypes}
              onChange={languageTypes => setDocLanguages(languageTypes)}
            />
          </CenteredDiv>
          <CenteredDiv>
            <TextField
              id='documentDescriptionTextField'
              disabled={isUploading}
              label={localizedStrings.description}
              variant='outlined'
              sx={classes.fileMetadata}
              multiline
              minRows={4}
              onChange={e => setDocDescription(e.target.value)}
              required
            />
          </CenteredDiv>
          <CenteredDiv>
            <FileUploader
              disabled={isUploading}
              style={classes.fileMetadata}
              handleFile={setFile}
              fileTypes='*'
              prompt={localizedStrings.chooseFile}
            />
          </CenteredDiv>
          <CenteredDiv>
            {file && file.size > 1900000000 ? (
              <div style={{ color: WtxColors.INNOVATION_RED }}>Files size is to large for upload.</div>
            ) : null}
          </CenteredDiv>
        </>
      </DialogContent>
    </SlideUpDialog>
  )
}
